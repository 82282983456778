.container {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 35%; /*16:9 Aspect Ratio (divide 9 by 16 = 0.5625)*/
}
  
/* Then style the iframe to fit in the container div with full height and width */
.responsiveiframe {
    position:absolute;  
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
}

.container::-webkit-scrollbar {
    display: none;
}

@media only screen and (max-width: 600px) {
   .container{
        padding-top:132%;
   }
}

@media only screen and (min-width: 600px) {
    /* .container{
        padding-top: 115%;
   } */
}